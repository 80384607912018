import './App.css';
import { Switch, Route, Redirect, BrowserRouter } from 'react-router-dom';
import InvoicePreviewer from './component/invoicePreviewer';
import NotFound from './component/notFound';

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path='/receipt/:id' component={InvoicePreviewer} />
        <Route exact path='/' component={NotFound} />
        <Redirect to='/404' />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
