export default function NotFound() {
    return (
        // <div className="not-found">
        //     <span>Seite nicht gefunden</span>
        // </div>
        <div className="App">
            <header className="App-header">
                <div className="logo">Deine digitale Rechnung</div>
            </header>
        </div>
    )
}